import styled from 'styled-components';

export const ApuracaoCampanhaListWrapper = styled.div`
  width: 100%;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 25px;
`;
