import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  Button,
  Table,
  TitleDescription,
  useXlsxJsonActions,
  ProviderXlsxJson,
} from '@digi-tim-19/components';
import { ApuracaoCampanhaListWrapper, ContainerButtons } from './styles';
import { routes } from '../../../config/routes';
import { useInvoiceOriginOptions } from '../useInvoiceOriginOptions';
import { AutoComplete, message, Modal, Select, TreeSelect, Upload } from 'antd';
import { useInMemoPagination } from '../../../hooks/useInMemoPagination';
import { padStart } from 'lodash';
import {
  EnumIncentiveCampaignStatus,
  SortFindManyIncentiveCampaignInput,
  ValidityEnum,
} from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';
import { usePagination } from '../../../hooks/usePagination';
import { debounce } from 'lodash';
import { treemap } from 'd3';
import { downloadXLSX } from '../../../utils/downloadXlsx';

type JSONData = {
  _id: string;
  [key: string]: string;
};

const configPage = {
  pageName: 'Apuração Campanha de incentivo',
  icon: 'trophy',
  description: 'Apuração de TIMCOINS para campanhas de incentivo e resultados.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    {
      title: 'Campanhas de Incentivo',
      link: routes.campanhasIncentivoListar.mount(),
    },
    { title: 'Apuração' },
    { title: 'Listar' },
  ],
};

export const ApuracaoIncentivo = () => {
  return (
    <ProviderXlsxJson>
      <ApuracaoIncentivoForm />
    </ProviderXlsxJson>
  );
};

export const ApuracaoIncentivoForm = () => {
  const invoiceOrigins = useInvoiceOriginOptions();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [loadingUpload, setLoading] = React.useState(false);
  const getIncentiveCampaign = usePagination('IncentiveCampaignPagination', {
    fetchOnMount: true,
    initial: {
      page: 0,
      perPage: 9999,
      filter: {
        validityEnum: ValidityEnum.Any,
      },
      sort: SortFindManyIncentiveCampaignInput.CreatedatDesc,
    },
    itemsFragment: `
      _id
      title
    `,
    parseResult: (data) => data.map((el: any) => ({ value: el._id, label: el.title })),
  });

  const xlsxJsonActions = useXlsxJsonActions();

  const [fullData, setFullData] = React.useState<JSONData[]>([]);

  React.useEffect(() => {
    const parsedData: JSONData[] = [];
    const rawData = xlsxJsonActions?.state?.json ?? [];

    if (!rawData.length && xlsxJsonActions.state.file !== null) {
      message.error(
        'Não foi possível ler os dados. certifique-se de que o arquivo está no formato correto e sem abas ocultas',
      );
      return;
    }

    if ((!rawData[0]?.CPF || !rawData[0]?.PONTOS) && xlsxJsonActions.state.file !== null && rawData.length > 0) {
      message.error('Formato de dados inválido. Verifique se as colunas estão de acordo com o modelo');
      return;
    }

    const errors: JSONData[] = [];

    rawData.forEach((el: any, i: number) => {
      let data = {
        ...el,
        line: i + 2,
        CPF: padStart(el.CPF, 11, '0'),
        STATUS: 'Pendente',
      };

      if (!el.CPF || el.CPF === '' || el.CPF === '00000000000') {
        data.CPF = '';
        data.STATUS = 'ERRO';
        data.DETALHES = 'CPF inválido';
        errors.push(data);
      }

      if (!el.PONTOS || Number.isNaN(Number(el.PONTOS)) || Number(el.PONTOS) <= 0) {
        data.STATUS = 'ERRO';
        data.DETALHES = 'TIMCoins inválidos';
        errors.push(data);
      }

      parsedData.push(data);
    });

    if (errors.length) {
      message.error('Foram encontrados erros na planilha. Verifique os detalhes na tabela abaixo');
      setFullData(errors);
    } else {
      setFullData(parsedData);
    }
  }, [xlsxJsonActions.state.json]);

  const { dataToRender, pagination } = useInMemoPagination({
    data: fullData,
    perPage: 15,
  });

  const [campaignId, setCampaignId] = React.useState<string | null>(null);
  const [invoiceOriginId, setInvoiceOriginId] = React.useState<string | null>(null);

  const incentiveCampaignPay = useClient('IncentiveCampaignPay');
  const loading = loadingUpload || incentiveCampaignPay.loading;

  const handlePayment = () => {
    if (loading || !campaignId || !invoiceOriginId || !fullData.length) {
      return;
    }
    incentiveCampaignPay
      .fetch({
        variables: {
          campaignId,
          invoiceOriginId,
          data: fullData,
        },
      })
      .then((cx) => {
        if (!Array.isArray(cx.result)) {
          return;
        }
        setFullData(cx.result as JSONData[]);
        setCampaignId(null);
        setInvoiceOriginId(null);
      });
  };

  const handleReport = () => {
    if (!fullData?.length) {
      message.error('Não há dados para exportar.');
      return;
    }

    const schema = {
      line: { label: 'LINHA' },
      CPF: { label: 'CPF' },
      PONTOS: { label: 'PONTOS' },
      STATUS: { label: 'STATUS' },
      DETALHES: { label: 'DETALHES' },
    };

    downloadXLSX('relatorio-apuracao', schema, fullData);
  };

  const disabled = loading || !campaignId || !invoiceOriginId || !fullData.length;

  return (
    <PageTemplate>
      <ApuracaoCampanhaListWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />
        <ContainerButtons>
          <Button
            onClick={() => {
              window.open('/modelo_upload_pontos.xlsx');
            }}
          >
            Baixar Modelo
          </Button>

          <Upload
            accept=".xlsx"
            multiple={false}
            showUploadList={false}
            fileList={fileList}
            onRemove={() => {
              setFileList([]);
              xlsxJsonActions.reset();
            }}
            openFileDialogOnClick={true}
            customRequest={(op) => {
              setLoading(true);
              setFileList([op.file]);
              xlsxJsonActions.setState('file', op.file);
              setLoading(false);
            }}
          >
            <Button icon="upload">Subir planilha</Button>
          </Upload>
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <TreeSelect
              style={{ width: '250px', marginRight: '15px' }}
              dropdownStyle={{ maxHeight: '40vh' }}
              treeData={invoiceOrigins}
              placeholder="Origem Fatura"
              onChange={(e: string) => {
                setInvoiceOriginId(e);
              }}
            />
            <div style={{ position: 'relative', width: 500 }}>
              <Select
                style={{ width: '100%' }}
                placeholder="Campanhas"
                onChange={(e: string) => {
                  setCampaignId(e);
                }}
                filterOption={(term, option) => {
                  const { title, value } = option.props ?? {};
                  return JSON.stringify({ title, value }).toLowerCase().includes(term.toLowerCase());
                }}
                // onSearch={(term: string) => {
                //   const search = term.length === 24 ? { _id: term } : { title: term };
                //   getIncentiveCampaign.updateFetch((s) => {
                //     return {
                //       ...s,
                //       filter: {
                //         validityEnum: ValidityEnum.Any,
                //         ...search,
                //       },
                //     };
                //   });
                // }}
                loading={getIncentiveCampaign.loading}
                showSearch={true}
              >
                {getIncentiveCampaign.loading ? (
                  <Select.Option key={'carregando'} value={'carregando'} title={'Carregando'}>
                    Carregando
                  </Select.Option>
                ) : (
                  getIncentiveCampaign.parsed.map((item) => (
                    <Select.Option key={item.value} value={item.value} title={item.label}>
                      {item.label}
                    </Select.Option>
                  ))
                )}
              </Select>
              <svg
                style={{ position: 'absolute', top: '25%', right: '10px' }}
                viewBox="64 64 896 896"
                focusable="false"
                className=""
                data-icon="search"
                width="1em"
                height="1em"
                fill="#00508c"
                fontSize="18px"
                aria-hidden="true"
              >
                <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
              </svg>
            </div>
            <Button
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  width: '50%',
                  title: `Confirmar upload e distribuição de TIMCOINS`,
                  content: <p>{`Verifique os dados na tabela antes de dar prosseguimento ao pagamento.`}</p>,
                  onOk: () => {
                    handlePayment();
                  },
                });
              }}
              disabled={disabled}
            >
              Distribuir TIMCOINS
            </Button>
          </div>
          <Button style={{ marginTop: '5px' }} onClick={() => handleReport()}>
            Exportar Resultado
          </Button>
        </ContainerButtons>
        {loading && <span>Carregando...</span>}
        <Table
          columns={[
            {
              title: 'LINHA',
              dataIndex: 'line',
              key: 'line',
            },
            {
              title: 'CPF do participante',
              dataIndex: 'CPF',
              key: 'CPF',
            },
            {
              title: 'TIMCoins',
              dataIndex: 'PONTOS',
              key: 'PONTOS',
            },
            {
              title: 'Status do pagamento',
              dataIndex: 'STATUS',
              key: 'STATUS',
            },
            {
              title: 'Detalhamento',
              dataIndex: 'DETALHES',
              key: 'DETALHES',
            },
          ]}
          dataSource={dataToRender}
          pagination={pagination}
        />
      </ApuracaoCampanhaListWrapper>
    </PageTemplate>
  );
};
